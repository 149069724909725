//* @see https://vueuse.org/useMediaQuery
import { useMediaQuery } from '@vueuse/core';

const media = {
  largeDesktopMediaQuery: '(min-width: 1280px)',
  desktopMediaQuery: '(min-width: 1025px)',
  mobileAndTabletMediaQuery: '(max-width: 1024px)',
  tabletMediaQuery: '(min-width: 768px) and (max-width: 1024px)',
  mobileMediaQuery: '(max-width: 767px)',
};

export const getMediaQuery = () => {
  const extractString = (value: string) => value.replace(/^"(.*)"$/, '$1');

  const isLargeDesktop = useMediaQuery(
    extractString(media.largeDesktopMediaQuery)
  );
  const isDesktop = useMediaQuery(extractString(media.desktopMediaQuery));
  const isMobileOrTablet = useMediaQuery(
    extractString(media.mobileAndTabletMediaQuery)
  );
  const isTablet = useMediaQuery(extractString(media.tabletMediaQuery));
  const isMobile = useMediaQuery(extractString(media.mobileMediaQuery));

  return {
    isLargeDesktop,
    isDesktop,
    isTablet,
    isMobileOrTablet,
    isMobile,
  };
};
